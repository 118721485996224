import { useEffect, useState } from "react";
import useWindowWidth from "@helpers/InitWidthDevice";
import { Select, Option } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import {
  Card,
  Typography
} from "@material-tailwind/react";
import { useLocation } from "react-router-dom";
import Message from "@components/common/Message";
import Loader from "@components/common/Loader";
import ApiService from "@services/ApiService";
import moment from "moment";
import { get } from "lodash";
import EditOrderUI from "UI/desktop/Orders/Edit";
import EditOrderUIMobile from "UI/mobile/Orders/Edit";
import { COUNTRY } from "@helpers/constants";

const OrderForm = (props: any) => {
  const { t, mobile } = props;
  const TABLE_HEAD = [t('no'), t('ticket-name'), t('quantity'), t('price'), t('discount'), t('total'), t('used-code')];
  const location = useLocation();
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [orderDetail, setOrderDetail] = useState(null as any);
  const { i18n: { language } = {} } = useTranslation();
  const [error, setError] = useState(
    {
      buyer: {
        name: '',
        email: '',
        phone: ''
      },
      tax: {
        company_name: '',
        recipient_name: '',
        company_address: '',
        recipient_phone: '',
        company_tax_code: '',
        recipient_address: ''
      }
    }
  );
  const [addVAT, setAddVAT] = useState(true);

  useEffect(() => {
    if (location && location.pathname && location.pathname !== '') {
      const start = location.pathname.indexOf('orders/');
      const end = location.pathname.indexOf('/edit');

      if (start !== -1 && end !== -1) {
        const order_id = location.pathname.slice(start + 7, end);

        setId(order_id);
      }
    }
  }, [location]);

  useEffect(() => {
    async function fetchOrderDetail() {
      setLoading(true);
      try {
        const result = await ApiService.get(`orders/${id}`);

        if (result) {
          if (!result.tax) {
            result.tax = {
              company_name: '',
              recipient_name: '',
              company_address: '',
              recipient_phone: '',
              company_tax_code: '',
              recipient_address: '',
              recipient_phone_area_code: ''
            }
          }

          setOrderDetail(result);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }

      setLoading(false);
    }

    if (id !== '') {
      fetchOrderDetail();
    }
  }, [id]);

  const handleChangeValue = (value: string, group: string, key: string) => {
    const order_temp = { ...orderDetail } as any;
    const errs = { ...error } as any;

    order_temp[group][key] = value;

    errs[group][key] = (value === '') ? t('error-field-require') : '';

    setError({ ...errs });
    setOrderDetail({ ...order_temp });

    if (key === 'email' || key === 'recipient_address') {
      validateEmail(value, key)
    }

    if (key.includes('phone')) {
      validatePhone(value, key);
    }
  }

  const validateEmail = (value: string, key: string) => {
    let err = '';

    if (!value) {
      err = t('error-field-require');
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        err = t('wrong-format-email');
      }
    }
    const errs = { ...error } as any;

    if (key === 'email') {
      errs.buyer.email = err;
    } else {
      errs.tax.recipient_address = err;
    }
    setError({ ...errs });
  }

  const validatePhone = (value: string, key: string) => {
    let err = '';

    if (!value) {
      err = t('error-field-require');
    } else {
      if (!/^\d{5,15}$/i.test(value)) {
        err = t('wrong-format-phone');
      }
    }

    const errs = { ...error } as any;

    if (key === 'phone') {
      errs.buyer.phone = err;
    } else {
      errs.tax[key] = err;
    }

    setError({ ...errs });
  }

  const handleUpdateOrder = async () => {
    setLoading(true);

    const errs = { ...error } as any;
    const keys_vat = Object.keys(errs.tax);
    const keys_buyer = Object.keys(errs.buyer);
    let check_data = true;

    if (orderDetail.is_vat === "0") {
      if (!addVAT) {
        keys_vat.forEach((item: any) => {
          if (!orderDetail.tax[item]) {
            errs.tax[item] = t('error-field-require');
          } else {
            errs.tax[item] = (orderDetail.tax[item] === '') ? t('error-field-require') : '';
          }
        });
      }
    }

    keys_vat.forEach((item: any) => {
      if (errs.tax[item] !== "") {
        check_data = false;
      }
    });

    keys_buyer.forEach((item: any) => {
      if (errs.buyer[item] !== "") {
        check_data = false;
      }
    });

    setError({ ...errs });

    if (check_data) {
      const submitData = {
        "buyer_name": orderDetail.buyer.name,
        "buyer_email": orderDetail.buyer.email,
        "buyer_phone": orderDetail.buyer.phone,
        "company_name": (orderDetail.tax.company_name === '') ? null : orderDetail.tax.company_name,
        "company_address": (orderDetail.tax.company_address === '') ? null : orderDetail.tax.company_address,
        "company_tax_code": (orderDetail.tax.company_tax_code === '') ? null : orderDetail.tax.company_tax_code,
        "recipient_name": (orderDetail.tax.recipient_name === '') ? null : orderDetail.tax.recipient_name,
        "recipient_address": (orderDetail.tax.recipient_address === '') ? null : orderDetail.tax.recipient_address,
        "recipient_phone": (orderDetail.tax.recipient_phone === '') ? null : orderDetail.tax.recipient_phone
      }
      try {
        const res = await ApiService.put(`orders/${id}`, submitData);

        if (res) {
          window.location.href = `/orders/${id}/detail`;
        }

      } catch (e: any) {
        setMessage(e.message);
        setMessageType('error');
      }
    }

    setLoading(false);
  }

  const getPaymentMethod = (method: string) => {
    const payment_method = method.toLowerCase();
    switch (payment_method) {
      case 'atm':
        return t('atm');
      case 'bank_transfer':
        return t('bank-transfer');
      case 'credit':
        return t('credit');
      case 'partner_vnpay':
        return 'Partner VNPay';
      case 'vn_pay_b':
        return 'VNPAY';
      case 'vnpay':
        return 'QR Code';
      case 'zalopay':
        return 'ZaloPay';
      case 'viet_qr':
        return 'Viet QR';
      case 'momo':
        return t('momo');
      case 'voucher':
        return t('coupon');
      case 'shipcode':
        return 'Ship Code';
      case 'free':
        return t('free-ticket');
      case 'bnpl':
        return t('bnpl');
      case 'viettel_money':
        return t('viettel_money');
      case 'v_atm':
        return t('v_atm');
      case 'v_credit':
        return t('v_credit');
      case 'payoo_atm':
        return t('payoo_atm');
      case 'payoo_credit':
        return t('payoo_credit');
      case 'onepay_viet_qr':
        return t('onepay_viet_qr');
      default:
        return method;
    }
  }

  return (
    <div className="order-form relative">
      <div className="order-form__header">
        <div className="header__label">{t('edit-order')}</div>
      </div>

      {
        orderDetail &&
        <div className="order-form__body">
          {
            orderDetail.buyer &&
            <div className="body__item">
              <div className="body__item__header">
                <div className="body__item__header__label">{t('buyer-information')}</div>
              </div>

              <div className="body__item__content flex-col">
                <div className={`${mobile ? 'w-[100%]' : 'w-[50%]'} mb-[24px]`}>
                  <div className="text-[14px] font-[500] text-[#262626] flex mb-[4px]">
                    <span>{t('full-name')}</span>

                    <img src="/images/icons/icon-required.svg" className="ml-[4px]" alt="icon-required" />
                  </div>

                  <input onChange={(e: any) => handleChangeValue(e.target.value, 'buyer', 'name')} value={orderDetail.buyer.name} type="text" className="w-[100%] h-[48px] px-[16px] rounded-[4px] border-[1px] border-[#C5CCD2]" />

                  {
                    error.buyer.name !== '' &&
                    <div className="text-[#ED4927] text-[14px] mt-[5px]">{error.buyer.name}</div>
                  }
                </div>

                <div className={`${mobile ? 'w-[100%]' : 'w-[50%]'} mb-[24px]`}>
                  <div className="text-[14px] font-[500] text-[#262626] flex mb-[4px]">
                    <span>{t('Email')}</span>

                    <img src="/images/icons/icon-required.svg" className="ml-[4px]" alt="icon-required" />
                  </div>

                  <input onChange={(e: any) => handleChangeValue(e.target.value, 'buyer', 'email')} value={orderDetail.buyer.email} type="text" className="w-[100%] h-[48px] px-[16px] rounded-[4px] border-[1px] border-[#C5CCD2]" />

                  {
                    error.buyer.email !== '' &&
                    <div className="text-[#ED4927] text-[14px] mt-[5px]">{error.buyer.email}</div>
                  }
                </div>

                <div className={`${mobile ? 'w-[100%]' : 'w-[50%]'} mb-[24px]`}>
                  <div className="text-[14px] font-[500] text-[#262626] flex mb-[4px]">
                    <span>{t('contact-phone')}</span>

                    <img src="/images/icons/icon-required.svg" className="ml-[4px]" alt="icon-required" />
                  </div>

                  <div className="w-[100%] h-[48px] pl-[8px] pr-[16px] rounded-[4px] border-[1px] border-[#C5CCD2] flex items-center">
                    <div className="select-phone-code">
                      <Select
                        value={orderDetail.buyer.phone_area_code}
                        onChange={(val: any) => handleChangeValue(val, 'buyer', 'phone_area_code')}
                        placeholder=""
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        className="border-none"
                      >
                        {
                          COUNTRY.map((item: any, index: number) => {
                            return (
                              <Option key={`${item.value}_${index}`}
                                value={item.code}>{item.value} | +{item.code}</Option>
                            )
                          })
                        }
                      </Select>
                    </div>

                    <input value={orderDetail.buyer.phone} onChange={(e: any) => handleChangeValue(e.target.value, 'buyer', 'phone')} type="text" className="input-phone" autoComplete="off" placeholder={t('phone-number')} />
                  </div>

                  {
                    error.buyer.phone !== '' &&
                    <div className="text-[#ED4927] text-[14px] mt-[5px]">{error.buyer.phone}</div>
                  }
                </div>
              </div>
            </div>
          }

          <div className="body__item">
            <div className="body__item__header">
              <div className="body__item__header__label">{t('order-information')}</div>
            </div>

            <div className="body__item__content">
              <div className="content__item">
                <div className="content__item__label">{t('order-code')}</div>
                <div className="content__item__value order-code">{orderDetail.code}</div>
              </div>

              <div className="content__item">
                <div className="content__item__label">{t('time-order')}</div>
                <div className="content__item__value">{moment(orderDetail.created_at).format('DD/MM/YYYY HH:mm:ss')}</div>
              </div>

              <div className="content__item">
                <div className="content__item__label">{t('order-amount')}</div>
                <div className="content__item__value order-code">đ {orderDetail.grand_total.toLocaleString('de-DE')}</div>
              </div>

              {
                orderDetail.purchased_at && orderDetail.purchased_at !== "1970-01-01 16:00:00" &&
                <div className="content__item">
                  <div className="content__item__label">{t('time-order-success')}</div>
                  <div className="content__item__value">{moment(orderDetail.purchased_at).format('DD/MM/YYYY HH:mm:ss')}</div>
                </div>
              }

              <div className="content__item">
                <div className="content__item__label">{t('order-status')}</div>
                <div className="content__item__value">
                  {
                    orderDetail.order_status === 'doi_thanh_toan' &&
                    <span className="status pending">{t('order-pending')}</span>
                  }

                  {
                    orderDetail.order_status === 'dat_hang_thanh_cong' &&
                    <span className="status success">{t('order-success')}</span>
                  }

                  {
                    orderDetail.order_status === 'da_huy' &&
                    <span className="status canceled">{t('order-canceled')}</span>
                  }

                  {
                    orderDetail.order_status === 'hoan_tien' &&
                    <span className="status refund">{t('order-refund')}</span>
                  }
                </div>
              </div>

              {
                orderDetail.order_status && orderDetail.order_status === 'da_huy' &&
                <div className="content__item">
                  <div className="content__item__label">{t('time-order-fail')}</div>
                  {
                    (orderDetail.expired_payment_at && orderDetail.expired_payment_at !== "1970-01-01 16:00:00") ?
                      <div className="content__item__value">{moment(orderDetail.expired_payment_at).format('DD/MM/YYYY HH:mm:ss')}</div>
                      :
                      <div className="content__item__value">{moment(orderDetail.canceled_at).format('DD/MM/YYYY HH:mm:ss')}</div>
                  }
                </div>
              }

              {
                orderDetail.transactions && orderDetail.transactions.length > 0 &&
                <div className="content__item">
                  <div className="content__item__label">{t('payment-method')}</div>
                  <div className="content__item__value">{getPaymentMethod(get(orderDetail.transactions[0], `payment_method_name_${language}`, ''))}</div>
                </div>
              }

              {
                orderDetail.event &&
                <div className="content__item">
                  <div className="content__item__label">{t('event-name')}</div>
                  <div className="content__item__value">{get(orderDetail.event, `name_${language}`, '')}</div>
                </div>
              }

              {
                orderDetail.event && orderDetail.event.event_type && orderDetail.event.event_type === 'sports' &&
                <div className="content__item">
                  <div className="content__item__label">{t('group-name')}</div>
                  {
                    orderDetail.extra && orderDetail.extra.checkout_data && orderDetail.extra.checkout_data.club_name && orderDetail.extra.checkout_data.club_name !== '' ?
                      <div className="content__item__value">{orderDetail.extra.checkout_data.club_name}</div>
                      :
                      <div className="content__item__value">N/A</div>
                  }
                </div>
              }

              {
                orderDetail.event && orderDetail.event.event_type && orderDetail.event.event_type === 'attractions' &&
                <div className="content__item">
                  <div className="content__item__label">{t('e-ticket')}</div>
                  {
                    orderDetail.ticket_use_for_date && orderDetail.ticket_use_for_date !== '' ?
                      <div className="content__item__value">{moment(orderDetail.ticket_use_for_date).format('DD/MM/YYYY')}</div>
                      :
                      <div className="content__item__value">N/A</div>
                  }
                </div>
              }


            </div>
          </div>

          <div className="body__item">
            <div className="flex items-center justify-between w-[100%] h-[64px] px-[24px] border-b-[1px] border-b-[#D9E1E9]">
              <div className="body__item__header__label text-[16px] font-bold text-[#262626]">{t('invoice-information')}</div>

              {
                (orderDetail.is_vat === "0") &&
                <>
                  {
                    addVAT ?
                      <div onClick={() => setAddVAT(false)} className="w-[125px] h-[40px] border-[1px] border-[#A6A1FD] rounded-[4px] flex items-center justify-center cursor-pointer">
                        <img src="/images/icons/icon-add-vat.svg" className="mr-[4px]" alt="icon-add-vat" />
                        <div className="text-[14px] font-[400] text-[#081774]">{t('add-vat')}</div>
                      </div>
                      :
                      <div onClick={() => setAddVAT(true)} className="w-[125px] h-[40px] border-[1px] border-[#A6A1FD] rounded-[4px] flex items-center justify-center cursor-pointer">
                        <img src="/images/icons/icon-delete.svg" className="mr-[4px]" alt="icon-add-vat" />
                        <div className="text-[14px] font-[400] text-[#081774]">{t('btn-cancel')}</div>
                      </div>
                  }
                </>
              }
            </div>

            {
              (orderDetail.is_vat === "1" || !addVAT) &&
              <div className="w-[100%] p-[24px] bg-white">
                <div className={`w-[100%] flex items-center justify-between mb-[24px] ${mobile && 'flex-col'}`}>
                  <div className={`${mobile ? 'w-[100%] mb-[24px]' : 'w-[49%]'}`}>
                    <div className="flex mb-[4px]">
                      <span className="text-[14px] text-[#262626] font-[500] mr-[4px]">{t('company-name')}</span>

                      <img src="/images/icons/icon-required.svg" alt="icon-required" />
                    </div>

                    <input onChange={(e: any) => handleChangeValue(e.target.value, 'tax', 'company_name')} value={orderDetail?.tax?.company_name} className="w-[100%] h-[48px] border-[1px] border-[#C5CCD2] rounded-[4px] px-[16px]" placeholder={t('company-name')} />

                    {
                      error.tax.company_name !== '' &&
                      <div className="text-[#ED4927] text-[14px] mt-[5px]">{error.tax.company_name}</div>
                    }
                  </div>

                  <div className={`${mobile ? 'w-[100%]' : 'w-[49%]'}`}>
                    <div className="flex mb-[4px]">
                      <span className="text-[14px] text-[#262626] font-[500] mr-[4px]">{t('recipient-name')}</span>

                      <img src="/images/icons/icon-required.svg" alt="icon-required" />
                    </div>

                    <input onChange={(e: any) => handleChangeValue(e.target.value, 'tax', 'recipient_name')} value={orderDetail?.tax?.recipient_name} className="w-[100%] h-[48px] border-[1px] border-[#C5CCD2] rounded-[4px] px-[16px]" placeholder={t('recipient-name')} />

                    {
                      error.tax.recipient_name !== '' &&
                      <div className="text-[#ED4927] text-[14px] mt-[5px]">{error.tax.recipient_name}</div>
                    }
                  </div>
                </div>

                <div className={`w-[100%] flex items-center justify-between mb-[24px] ${mobile && 'flex-col'}`}>
                  <div className={`${mobile ? 'w-[100%] mb-[24px]' : 'w-[49%]'}`}>
                    <div className="flex mb-[4px]">
                      <span className="text-[14px] text-[#262626] font-[500] mr-[4px]">{t('company-address')}</span>

                      <img src="/images/icons/icon-required.svg" alt="icon-required" />
                    </div>

                    <input onChange={(e: any) => handleChangeValue(e.target.value, 'tax', 'company_address')} value={orderDetail?.tax?.company_address} className="w-[100%] h-[48px] border-[1px] border-[#C5CCD2] rounded-[4px] px-[16px]" placeholder={t('company-address')} />

                    {
                      error.tax.company_address !== '' &&
                      <div className="text-[#ED4927] text-[14px] mt-[5px]">{error.tax.company_address}</div>
                    }
                  </div>

                  <div className={`${mobile ? 'w-[100%]' : 'w-[49%]'}`}>
                    <div className="flex mb-[4px]">
                      <span className="text-[14px] text-[#262626] font-[500] mr-[4px]">{t('recipient-phone')}</span>

                      <img src="/images/icons/icon-required.svg" alt="icon-required" />
                    </div>

                    <div className="w-[100%] h-[48px] pl-[8px] pr-[16px] rounded-[4px] border-[1px] border-[#C5CCD2] flex items-center">
                      <div className="select-phone-code">
                        <Select
                          value={(orderDetail?.tax?.recipient_phone_area_code && orderDetail?.tax?.recipient_phone_area_code !== '') ? orderDetail?.tax?.recipient_phone_area_code : '84'}
                          onChange={(val: any) => handleChangeValue(val, 'tax', 'recipient_phone_area_code')}
                          placeholder=""
                          onPointerEnterCapture="none" onPointerLeaveCapture="none"
                          className="border-none"
                        >
                          {
                            COUNTRY.map((item: any, index: number) => {
                              return (
                                <Option key={`${item.value}_${index}`}
                                  value={item.code}>{item.value} | +{item.code}</Option>
                              )
                            })
                          }
                        </Select>
                      </div>

                      <input value={orderDetail?.tax?.recipient_phone} onChange={(e: any) => handleChangeValue(e.target.value, 'tax', 'recipient_phone')} type="text" className="input-phone" autoComplete="off" placeholder={t('phone-number')} />
                    </div>

                    {
                      error.tax.recipient_phone !== '' &&
                      <div className="text-[#ED4927] text-[14px] mt-[5px]">{error.tax.recipient_phone}</div>
                    }
                  </div>
                </div>

                <div className={`w-[100%] flex items-center justify-between mb-[24px] ${mobile && 'flex-col'}`}>
                  <div className={`${mobile ? 'w-[100%] mb-[24px]' : 'w-[49%]'}`}>
                    <div className="flex mb-[4px]">
                      <span className="text-[14px] text-[#262626] font-[500] mr-[4px]">{t('company-tax')}</span>

                      <img src="/images/icons/icon-required.svg" alt="icon-required" />
                    </div>

                    <input onChange={(e: any) => handleChangeValue(e.target.value, 'tax', 'company_tax_code')} value={orderDetail?.tax?.company_tax_code} className="w-[100%] h-[48px] border-[1px] border-[#C5CCD2] rounded-[4px] px-[16px]" placeholder={t('company-tax')} />

                    {
                      error.tax.company_tax_code !== '' &&
                      <div className="text-[#ED4927] text-[14px] mt-[5px]">{error.tax.company_tax_code}</div>
                    }
                  </div>

                  <div className={`${mobile ? 'w-[100%]' : 'w-[49%]'}`}>
                    <div className="flex mb-[4px]">
                      <span className="text-[14px] text-[#262626] font-[500] mr-[4px]">{t('Email')}</span>

                      <img src="/images/icons/icon-required.svg" alt="icon-required" />
                    </div>

                    <input onChange={(e: any) => handleChangeValue(e.target.value, 'tax', 'recipient_address')} value={orderDetail?.tax?.recipient_address} className="w-[100%] h-[48px] border-[1px] border-[#C5CCD2] rounded-[4px] px-[16px]" placeholder={t('recipient-name')} />

                    {
                      error.tax.recipient_address !== '' &&
                      <div className="text-[#ED4927] text-[14px] mt-[5px]">{error.tax.recipient_address}</div>
                    }
                  </div>
                </div>
              </div>
            }

          </div>

          {
            orderDetail.items && orderDetail.items.length > 0 &&
            <div className="body__item purchased-group">
              <div className="body__item__content purchased">
                <div className="body__item__content-label">{t('purchased-product')}</div>

                {
                  !mobile &&
                  <div className="purchased__board">
                    <Card
                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      className="h-full w-full overflow-scroll overflow-y-hidden" placeholder="Card name table">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head) => (
                              <th
                                key={head}
                                className="p-4"
                              >
                                <Typography
                                  variant="small"
                                  className="table-label"
                                  placeholder="Typography"
                                  onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            orderDetail.items.length > 0 &&
                            orderDetail.items.map((item: any, index: number) => {
                              const isLast = index === orderDetail.items.length - 1;
                              const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                              return (
                                <tr key={`event_${item.id}_${index}`}>
                                  <td className={classes}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                    >
                                      {index + 1}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal order-code"
                                      placeholder="Typography"
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                    >
                                      {get(item, `product_name_${language}`, '')}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                    >
                                      {item.quantity}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                    >
                                      đ {item.product_price.toLocaleString('de-DE')}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                    >
                                      đ {item.discount_amount.toLocaleString('de-DE')}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                    >
                                      đ {item.grand_total.toLocaleString('de-DE')}
                                    </Typography>
                                  </td>
                                  <td className={classes}>

                                    <Typography
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                    >
                                      {
                                        orderDetail.coupons && orderDetail.coupons.length > 0 &&
                                        orderDetail.coupons.map((coupon: any, index: number) => {
                                          return (
                                            <span key={`coupon_${index}`}>{coupon}</span>
                                          )
                                        })
                                      }
                                    </Typography>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </Card>
                  </div>
                }

              </div>
            </div>
          }

          {
            (mobile && orderDetail.items && orderDetail.items.length > 0) &&
            <div className="purchased__list">
              {
                orderDetail.items.map((item: any, index: number) => {
                  return (
                    <div className="purchased__item" key={`purchased__item__${index}`}>
                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('no')}</div>
                        <div className="purchased__item__value">{index + 1}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('ticket-name')}</div>
                        <div className="purchased__item__value">{get(item, `product_name_${language}`, '')}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('quantity')}</div>
                        <div className="purchased__item__value">{item.quantity}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('price')}</div>
                        <div className="purchased__item__value">đ {item.product_price.toLocaleString('de-DE')}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('discount')}</div>
                        <div className="purchased__item__value">đ {item.discount_amount.toLocaleString('de-DE')}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('total')}</div>
                        <div className="purchased__item__value">đ {item.grand_total.toLocaleString('de-DE')}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('used-code')}</div>
                        <div className="purchased__item__value">
                          {
                            orderDetail.coupons && orderDetail.coupons.length > 0 &&
                            orderDetail.coupons.map((coupon: any, index: number) => {
                              return (
                                <span key={`${coupon}_${index}`}>{coupon}</span>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          }
        </div>
      }

      <div className="w-[100%] h-[84px] bg-white sticky bottom-0 flex justify-end items-center">
        <a href={`/orders/${id}/detail`}>
          <div className="w-[88px] h-[48px] flex items-center justify-center border-[1px] border-[#E9ECFF] rounded-[4px] text-[#011BB6] mr-[12px] text-[14px] cursor-pointer">{t('btn-cancel')}</div>
        </a>

        <div onClick={handleUpdateOrder} className="w-[88px] h-[48px] flex items-center justify-center border-[1px] border-[#011BB6] bg-[#011BB6] rounded-[4px] text-white mr-[24px] text-[14px] cursor-pointer">{t('btn-save')}</div>

      </div>

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }

      {
        loading &&
        <Loader />
      }
    </div>
  )
}

const EditOrder = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <EditOrderUI
        t={t}
        OrderForm={OrderForm}
      />
    )
  } else {
    return (
      <EditOrderUIMobile
        t={t}
        OrderForm={OrderForm}
      />
    )
  }
}

export default EditOrder;