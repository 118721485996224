import { useEffect, useState } from "react";
import useWindowWidth from "@helpers/InitWidthDevice";
import { Button, Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import OrderDetailUI from "UI/desktop/Orders/Detail";
import OrderDetailUIMobile from "UI/mobile/Orders/Detail";
import { MagnifyingGlassIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import {
  Card,
  Typography
} from "@material-tailwind/react";
import { UserIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import Message from "@components/common/Message";
import Loader from "@components/common/Loader";
import ApiService from "@services/ApiService";
import moment from "moment";
import { get } from "lodash";
import Pagination from "@components/common/Pagination";

const OrderForm = (props: any) => {
  const { t, mobile } = props;
  const [openAction, setOpenAction] = useState(false);
  const TABLE_HEAD = [t('no'), t('ticket-name'), t('quantity'), t('price'), t('discount'), t('total'), t('used-code')];
  const location = useLocation();
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [orderDetail, setOrderDetail] = useState(null as any);
  const { i18n: { language } = {} } = useTranslation();
  const [page, setPage] = useState(1);
  const [paging, setPaging] = useState(null as any);
  const [attendants, setAttendants] = useState([] as any);
  const [filter, setFilter] = useState(true);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (location && location.pathname && location.pathname !== '') {
      const start = location.pathname.indexOf('orders/');
      const end = location.pathname.indexOf('/detail');

      if (start !== -1 && end !== -1) {
        const order_id = location.pathname.slice(start + 7, end);

        setId(order_id);
      }
    }
  }, [location]);

  useEffect(() => {
    async function fetchOrderDetail() {
      setLoading(true);
      try {
        const result = await ApiService.get(`orders/${id}`);

        if (result) {
          setOrderDetail(result);
          setFilter(true);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
        setLoading(false);
      }
    }

    if (id !== '') {
      fetchOrderDetail();
    }
  }, [id]);

  useEffect(() => {
    async function fetchAttendants() {
      setLoading(true);
      try {
        const result = await ApiService.get(`orders/${id}/attendants/page/${page}?limit=12&email=${email}`);

        if (result) {
          setAttendants(result.attendants);
          setPaging(result.paging);
          setFilter(false);
        }
      } catch (error: any) {
        setMessage(error.message);
        setMessageType('error');
      }

      setLoading(false);
    }
    if (filter && id !== '') {
      fetchAttendants();
    }
  }, [page, filter, id, email]);

  const handleSendSuccessEmail = async () => {
    setLoading(true);
    try {
      const result = await ApiService.post(`orders/send-email/payment-success/orders/${id}`, {});

      if (result) {
        setMessage(t("send-email-successfully"));
        setMessageType("success");
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const handleSendEmailForParticipant = async (participant_id: string) => {
    setLoading(true);
    try {
      const result = await ApiService.post(`orders/send-email/payment-success/orders/${id}/participant/${participant_id}`, {});

      if (result) {
        setMessage(t("send-email-successfully"));
        setMessageType("success");
      }
    } catch (error: any) {
      setMessage(error.message);
      setMessageType('error');
    }

    setLoading(false);
  }

  const handleRedirectParticipant = (item: any) => {
    if (orderDetail && orderDetail.event && orderDetail.event.event_type && orderDetail.event.event_type === 'attractions') {
      window.location.href = `/attendees/attractions/${item.participant_id}/detail`;
    } else {
      window.location.href = `/attendees/sports/${item.participant_id}/detail`;
    }
  }

  const getPaymentMethod = (method: string) => {
    const payment_method = method.toLowerCase();
    switch (payment_method) {
      case 'atm':
        return t('atm');
      case 'bank_transfer':
        return t('bank-transfer');
      case 'credit':
        return t('credit');
      case 'partner_vnpay':
        return 'Partner VNPay';
      case 'vn_pay_b':
        return 'VNPAY';
      case 'vnpay':
        return 'QR Code';
      case 'zalopay':
        return 'ZaloPay';
      case 'viet_qr':
        return 'Viet QR';
      case 'momo':
        return t('momo');
      case 'voucher':
        return t('coupon');
      case 'shipcode':
        return 'Ship Code';
      case 'free':
        return t('free-ticket');
      case 'bnpl':
        return t('bnpl');
      case 'viettel_money':
        return t('viettel_money');
      case 'v_atm':
        return t('v_atm');
      case 'v_credit':
        return t('v_credit');
      case 'payoo_atm':
        return t('payoo_atm');
      case 'payoo_credit':
        return t('payoo_credit');
      case 'onepay_viet_qr':
        return t('onepay_viet_qr');
      default:
        return method;
    }
  }

  return (
    <div className="order-form">
      <div className="order-form__header">
        <div className="header__label">{t('order-detail')}</div>

        {
          !mobile ?
            <div className="flex items-center justify-end">
              {
                orderDetail?.order_status === 'dat_hang_thanh_cong' &&
                <a href={`/orders/${id}/edit`}>
                  <div className="h-[48px] px-[12px] flex items-center justify-center border-[1px] rounded-[4px] border-[#011BB6] cursor-pointer">
                    <img src="/images/icons/icon-edit-detail.svg" className="mr-[4px]" alt="icon-edit" />

                    <div className="text-[14px] font-[500] text-[#011BB6]">{t('edit')}</div>
                  </div>
                </a>
              }

              {
                (orderDetail && orderDetail.order_status && orderDetail.order_status === 'dat_hang_thanh_cong') &&
                <Button
                  placeholder="Button resend email"
                  className="btn-resend ml-[12px]"
                  onPointerEnterCapture="none" onPointerLeaveCapture="none"
                  onClick={handleSendSuccessEmail}>
                  <EnvelopeIcon className="w-4 h-4 icon-email" />

                  <span>{t('resend-email')}</span>
                </Button>
              }
            </div>
            :
            <div className="flex items-center">
              <a href={`/orders/${id}/edit`} className="mr-[36px]">
                <img src="/images/icons/icon-edit-mobile.svg" alt="icon-edit" />
              </a>
              <Popover
                animate={{
                  mount: { scale: 1, y: 0 },
                  unmount: { scale: 0, y: 25 },
                }}
                placement="bottom"
                open={openAction} handler={() => setOpenAction(!openAction)}
              >
                <PopoverHandler>
                  <EllipsisHorizontalIcon className="w-7 h-7 icon-list-btn" />

                </PopoverHandler>
                <PopoverContent
                  placeholder="popover btn list action"
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  id="popover-list-action">
                  {
                    (orderDetail && orderDetail.order_status && orderDetail.order_status === 'dat_hang_thanh_cong') &&
                    <Button
                      placeholder="Button resend email"
                      className="btn-resend"
                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      onClick={handleSendSuccessEmail}>
                      <EnvelopeIcon className="w-4 h-4 icon-email" />

                      <span>{t('resend-email')}</span>
                    </Button>
                  }
                </PopoverContent>
              </Popover>
            </div>
        }
      </div>

      {
        orderDetail &&
        <div className={!openAction ? "order-form__body" : "order-form__body overlay"}>
          {
            orderDetail.buyer &&
            <div className="body__item">
              <div className="body__item__header">
                <div className="body__item__header__label">{t('buyer-information')}</div>
              </div>

              <div className="body__item__content">
                <div className="content__item full">
                  <div className="content__item__label">{t('full-name')}</div>
                  <div className="content__item__value">{orderDetail.buyer.name}</div>
                </div>

                <div className="content__item full">
                  <div className="content__item__label">Email</div>
                  <div className="content__item__value">
                    {orderDetail.buyer.email}

                    {
                      orderDetail.is_sent_email_success &&
                      <span className="send-email-payment-success">({t('send-email-payment-success')})</span>
                    }
                  </div>
                </div>

                <div className="content__item full">
                  <div className="content__item__label">{t('phone')}</div>
                  <div className="content__item__value">+{orderDetail.buyer.phone_area_code} {orderDetail.buyer.phone}</div>
                </div>
              </div>
            </div>
          }

          <div className="body__item">
            <div className="body__item__header">
              <div className="body__item__header__label">{t('order-information')}</div>
            </div>

            <div className="body__item__content">
              <div className="content__item">
                <div className="content__item__label">{t('order-code')}</div>
                <div className="content__item__value order-code">{orderDetail.code}</div>
              </div>

              <div className="content__item">
                <div className="content__item__label">{t('time-order')}</div>
                <div className="content__item__value">{moment(orderDetail.created_at).format('DD/MM/YYYY HH:mm:ss')}</div>
              </div>

              <div className="content__item">
                <div className="content__item__label">{t('order-amount')}</div>
                <div className="content__item__value order-code">đ {orderDetail.grand_total.toLocaleString('de-DE')}</div>
              </div>

              {
                orderDetail.purchased_at && orderDetail.purchased_at !== "1970-01-01 16:00:00" &&
                <div className="content__item">
                  <div className="content__item__label">{t('time-order-success')}</div>
                  <div className="content__item__value">{moment(orderDetail.purchased_at).format('DD/MM/YYYY HH:mm:ss')}</div>
                </div>
              }

              <div className="content__item">
                <div className="content__item__label">{t('order-status')}</div>
                <div className="content__item__value">
                  {
                    orderDetail.order_status === 'doi_thanh_toan' &&
                    <span className="status pending">{t('order-pending')}</span>
                  }

                  {
                    orderDetail.order_status === 'dat_hang_thanh_cong' &&
                    <span className="status success">{t('order-success')}</span>
                  }

                  {
                    orderDetail.order_status === 'da_huy' &&
                    <span className="status canceled">{t('order-canceled')}</span>
                  }

                  {
                    orderDetail.order_status === 'hoan_tien' &&
                    <span className="status refund">{t('order-refund')}</span>
                  }
                </div>
              </div>

              {
                orderDetail.order_status && orderDetail.order_status === 'da_huy' &&
                <div className="content__item">
                  <div className="content__item__label">{t('time-order-fail')}</div>
                  {
                    (orderDetail.expired_payment_at && orderDetail.expired_payment_at !== "1970-01-01 16:00:00") ?
                      <div className="content__item__value">{moment(orderDetail.expired_payment_at).format('DD/MM/YYYY HH:mm:ss')}</div>
                      :
                      <div className="content__item__value">{moment(orderDetail.canceled_at).format('DD/MM/YYYY HH:mm:ss')}</div>
                  }
                </div>
              }

              {
                orderDetail.transactions && orderDetail.transactions.length > 0 &&
                <div className="content__item">
                  <div className="content__item__label">{t('payment-method')}</div>
                  <div className="content__item__value">{getPaymentMethod(get(orderDetail.transactions[0], `payment_method_name_${language}`, ''))}</div>
                </div>
              }

              {
                orderDetail.event &&
                <div className="content__item">
                  <div className="content__item__label">{t('event-name')}</div>
                  <div className="content__item__value">{get(orderDetail.event, `name_${language}`, '')}</div>
                </div>
              }

              {
                orderDetail.event && orderDetail.event.event_type && orderDetail.event.event_type === 'sports' &&
                <div className="content__item">
                  <div className="content__item__label">{t('group-name')}</div>
                  {
                    orderDetail.extra && orderDetail.extra.checkout_data && orderDetail.extra.checkout_data.club_name && orderDetail.extra.checkout_data.club_name !== '' ?
                      <div className="content__item__value">{orderDetail.extra.checkout_data.club_name}</div>
                      :
                      <div className="content__item__value">N/A</div>
                  }
                </div>
              }

              {
                orderDetail.event && orderDetail.event.event_type && orderDetail.event.event_type === 'attractions' &&
                <div className="content__item">
                  <div className="content__item__label">{t('e-ticket')}</div>
                  {
                    orderDetail.ticket_use_for_date && orderDetail.ticket_use_for_date !== '' ?
                      <div className="content__item__value">{moment(orderDetail.ticket_use_for_date).format('DD/MM/YYYY')}</div>
                      :
                      <div className="content__item__value">N/A</div>
                  }
                </div>
              }


            </div>
          </div>

          {
            orderDetail.is_vat === "1" &&
            <div className="body__item">
              <div className="body__item__header">
                <div className="body__item__header__label">{t('invoice-information')}</div>
              </div>

              <div className="body__item__content">
                <div className="content__item">
                  <div className="content__item__label">{t('company-name')}</div>
                  <div className="content__item__value">{orderDetail.tax?.company_name}</div>
                </div>

                <div className="content__item">
                  <div className="content__item__label">{t('recipient-name')}</div>
                  <div className="content__item__value">{orderDetail.tax?.recipient_name}</div>
                </div>

                <div className="content__item">
                  <div className="content__item__label">{t('company-address')}</div>
                  <div className="content__item__value">{orderDetail.tax?.company_address}</div>
                </div>

                <div className="content__item">
                  <div className="content__item__label">{t('recipient-phone')}</div>
                  <div className="content__item__value">{orderDetail.tax?.recipient_phone}</div>
                </div>

                <div className="content__item">
                  <div className="content__item__label">{t('company-tax')}</div>
                  <div className="content__item__value">{orderDetail.tax?.company_tax_code}</div>
                </div>

                <div className="content__item">
                  <div className="content__item__label">{t('email')}</div>
                  <div className="content__item__value">{orderDetail.tax?.recipient_address}</div>
                </div>
              </div>
            </div>
          }

          {
            orderDetail.items && orderDetail.items.length > 0 &&
            <div className="body__item purchased-group">
              <div className="body__item__content purchased">
                <div className="body__item__content-label">{t('purchased-product')}</div>

                {
                  !mobile &&
                  <div className="purchased__board">
                    <Card
                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                      className="h-full w-full overflow-scroll overflow-y-hidden" placeholder="Card name table">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head) => (
                              <th
                                key={head}
                                className="p-4"
                              >
                                <Typography
                                  variant="small"
                                  className="table-label"
                                  placeholder="Typography"
                                  onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            orderDetail.items.length > 0 &&
                            orderDetail.items.map((item: any, index: number) => {
                              const isLast = index === orderDetail.items.length - 1;
                              const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                              return (
                                <tr key={`event_${item.id}_${index}`}>
                                  <td className={classes}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                    >
                                      {index + 1}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal order-code"
                                      placeholder="Typography"
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                    >
                                      {get(item, `product_name_${language}`, '')}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                    >
                                      {item.quantity}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                    >
                                      đ {item.product_price.toLocaleString('de-DE')}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                    >
                                      đ {item.discount_amount.toLocaleString('de-DE')}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                    >
                                      đ {item.grand_total.toLocaleString('de-DE')}
                                    </Typography>
                                  </td>
                                  <td className={classes}>

                                    <Typography
                                      onPointerEnterCapture="none" onPointerLeaveCapture="none"
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      placeholder="Typography"
                                    >
                                      {
                                        orderDetail.coupons && orderDetail.coupons.length > 0 &&
                                        orderDetail.coupons.map((coupon: any, index: number) => {
                                          return (
                                            <span key={`coupon_${index}`}>{coupon}</span>
                                          )
                                        })
                                      }
                                    </Typography>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </Card>
                  </div>
                }

              </div>
            </div>
          }

          {
            (mobile && orderDetail.items && orderDetail.items.length > 0) &&
            <div className="purchased__list">
              {
                orderDetail.items.map((item: any, index: number) => {
                  return (
                    <div className="purchased__item" key={`purchased__item__${index}`}>
                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('no')}</div>
                        <div className="purchased__item__value">{index + 1}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('ticket-name')}</div>
                        <div className="purchased__item__value">{get(item, `product_name_${language}`, '')}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('quantity')}</div>
                        <div className="purchased__item__value">{item.quantity}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('price')}</div>
                        <div className="purchased__item__value">đ {item.product_price.toLocaleString('de-DE')}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('discount')}</div>
                        <div className="purchased__item__value">đ {item.discount_amount.toLocaleString('de-DE')}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('total')}</div>
                        <div className="purchased__item__value">đ {item.grand_total.toLocaleString('de-DE')}</div>
                      </div>

                      <div className="purchased__item__row">
                        <div className="purchased__item__label">{t('used-code')}</div>
                        <div className="purchased__item__value">
                          {
                            orderDetail.coupons && orderDetail.coupons.length > 0 &&
                            orderDetail.coupons.map((coupon: any, index: number) => {
                              return (
                                <span key={`${coupon}_${index}`}>{coupon}</span>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          }

          {
            (attendants && attendants.length > 0 && orderDetail && orderDetail.order_status === 'dat_hang_thanh_cong') &&
            <div className="body__item purchased-group">
              <div className="body__item__content purchased">
                <div className="body__item__content-label">{t('participant-information')}</div>

                {
                  !mobile &&
                  <div className="body__item__search-group">
                    <div className="search-group__label">Email</div>

                    <div className="search-group__row">
                      <input type="text"
                        className="search-group__input"
                        placeholder={t('search-email-placeholder')}
                        onChange={(e: any) => setEmail(e.target.value)} />

                      <Button
                        placeholder="Button search coupon"
                        className="btn-search"
                        onPointerEnterCapture="none" onPointerLeaveCapture="none"
                        onClick={() => setFilter(true)}>
                        <MagnifyingGlassIcon className="w-4 h-4 icon-search" />
                        <span>{t('search')}</span>
                      </Button>
                    </div>

                    <div className="participants">
                      {
                        attendants.map((item: any, index: number) => {
                          return (
                            <div className="participant-item" key={`${item.attendant_id}_${index}`}>
                              <div className="participant-item__avatar" onClick={() => handleRedirectParticipant(item)}>
                                <UserIcon className="w-4 h-4 icon-avatar" />
                              </div>

                              <div className="participant-item__information" onClick={() => handleRedirectParticipant(item)}>
                                <div className="information__username">{item.first_name} {item.last_name}</div>
                                <div className="information__email">{item.email}</div>

                                {
                                  (item.is_sent_email_success !== "0") ?
                                    <div className="information__email-status sent">
                                      {t('sent-email')}
                                    </div>
                                    :
                                    <div className="information__email-status not-sent">
                                      {t('not-sent-email')}
                                    </div>
                                }

                              </div>

                              {
                                (orderDetail && orderDetail.order_status && orderDetail.order_status === 'dat_hang_thanh_cong') &&
                                <EnvelopeIcon className="icon-email" onClick={() => handleSendEmailForParticipant(item.participant_id)} />
                              }
                            </div>
                          )
                        })
                      }

                    </div>

                    {
                      paging &&
                      <div className="attendant-bottom">
                        {
                          paging.total_page > 1 &&
                          <Pagination
                            setPage={setPage}
                            paging={paging}
                            setFilter={setFilter}
                          />
                        }
                      </div>
                    }
                  </div>
                }

              </div>
            </div>
          }

          {
            (mobile && attendants && attendants.length > 0 && orderDetail && orderDetail.order_status === 'dat_hang_thanh_cong') &&
            <div className="participant__form">
              <div className="filter-group">
                <div className="filter-group__label">{t('email')}</div>

                <div className="filter-group__row">
                  <input type="text"
                    className="search-group__input"
                    placeholder={t('search-email-placeholder')}
                    onChange={(e: any) => setEmail(e.target.value)} />

                  <Button
                    placeholder="Button search coupon"
                    className="btn-search"
                    onPointerEnterCapture="none" onPointerLeaveCapture="none"
                    onClick={() => setFilter(true)}>
                    <MagnifyingGlassIcon className="w-4 h-4 icon-search" />
                    <span>{t('search')}</span>
                  </Button>
                </div>
              </div>

              {
                attendants.map((item: any, index: number) => {
                  return (
                    <div className="participant-item" key={`${item.item_id}_${index}`}>
                      <div className="participant-item__avatar" onClick={() => handleRedirectParticipant(item)}>
                        <UserIcon className="w-4 h-4 icon-avatar" />
                      </div>

                      <div className="participant-item__information" onClick={() => handleRedirectParticipant(item)}>
                        <div className="information__username">{item.first_name} {item.last_name}</div>
                        <div className="information__email">{item.email}</div>

                        {
                          (item.is_sent_email_success !== "0") ?
                            <div className="information__email-status sent">
                              {t('sent-email')}
                            </div>
                            :
                            <div className="information__email-status not-sent">
                              {t('not-sent-email')}
                            </div>
                        }

                      </div>

                      {
                        (orderDetail && orderDetail.order_status && orderDetail.order_status === 'dat_hang_thanh_cong') &&
                        <EnvelopeIcon className="icon-email" onClick={() => handleSendEmailForParticipant(item.participant_id)} />
                      }
                    </div>
                  )
                })
              }

              {
                paging &&
                <div className="attendant-bottom">
                  {
                    paging.total_page > 1 &&
                    <Pagination
                      setPage={setPage}
                      paging={paging}
                      setFilter={setFilter}
                    />
                  }
                </div>
              }
            </div>
          }
        </div>
      }

      {
        message !== '' &&
        <Message
          message={message}
          messageType={messageType}
          setMessage={setMessage}
        />
      }

      {
        loading &&
        <Loader />
      }
    </div>
  )
}

const OrderDetail = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  if (windowWidth && windowWidth > 768) {
    return (
      <OrderDetailUI
        t={t}
        OrderForm={OrderForm}
      />
    )
  } else {
    return (
      <OrderDetailUIMobile
        t={t}
        OrderForm={OrderForm}
      />
    )
  }
}

export default OrderDetail;